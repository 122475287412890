import React from 'react';
import ExploreSourceSelector from './ExploreSourceSelector';
import Header from './components/Header';
import SearchBar from './SearchBarBasic';
import SkillsGrid from './SkillsGrid';
import categories from './skill-categories.json';
import BlogIndex from './screens/Blog/BlogIndex';
import Courses from './screens/Courses';

const queryString = require('query-string');


class Explore extends React.Component {  

  constructor(props) {
    super(props);

    const qs = queryString.parse(this.props.location.search);
    
    this.state = {
      source: qs.source ? qs.source : 'skills',
      category: qs.category? qs.category : '',
      favorites: qs.favorites? qs.favorites : '',
      proficiencies: qs.proficiencies? qs.proficiencies : ''
    };
  }

  onFilterUpdated(filter) {
    this.setState({source: filter})

    if (this.searchBarRef)
      this.searchBarRef.resetData();
  }

  //
  // RENDER

  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper" style={{paddingTop: '18vh'}}>
          <center>
            <ExploreSourceSelector 
              source={this.state.source} 
              onFilterUpdated={this.onFilterUpdated.bind(this)} 
            />
          </center>
        </div>
        {this.state.source === 'skills' && 
          <>
            <div className='content-wrapper'>
              <SearchBar 
                openSkillpage={true} 
                hideHeader={true} 
                ref={ x => this.searchBarRef = x }
                source={this.state.source}            
              />
              <br />
            </div>
            <SkillsGrid proficiencies={this.state.proficiencies} favorites={this.state.favorites} category={this.state.category} />
            {this.renderLegend()}
          </>
        }
        {this.state.source === 'courses' && <Courses />}
        {this.state.source === 'blog' && <BlogIndex location={this.props.location} />}
      </div>
    );
  }

  renderLegend() {
    return (
      <div className="content-wrapper">
        <h4>Legend</h4>
        <ul style={{listStyle: 'none'}}>
          <li>👔 = Professional</li>
          <li>🧑 = Personal</li>
          <li>&nbsp;</li>
          {
            Object.values(categories).filter(c => c.isActive).map((c, index) => 
              <li>
                <img 
                  key={c.name}
                  data-index={index}
                  src={`${process.env['PUBLIC_URL']}/${c.name.replace('/', '-')}@72x.png`}
                  alt={c.name}
                  width='24'
                  />
                <span> {c.name}</span>
              </li>
            )
          }
        </ul>
      </div>
    );
  }
}

export default Explore;