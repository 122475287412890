import React from 'react';
import Header from '../../components/Header';
import Proficiency from '../../Proficiency';

class FAQ extends React.Component {

  componentDidMount() {
    document.title = "Frequently Asked Questions";
  }

  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper"  style={{paddingTop: '16vh'}}>
          <center><h1>Frequently Asked Questions</h1></center>

          <h2>Are all the resources free?</h2>
          <p>Most of them are. For each skill, we are linking to top-quality resources, and most of the times, they come in the form of free articles or videos online.</p>
          <p>When articles aren't enough, we find the best courses and books on the topic. These are usually paid. For most of them, we don't make money from your purchase. We disclose our affiliate links inline with the link for those we make money from.</p>
          <p>We also provide learning aid in the form of courses, tools, and coaching. Some are paid, some are free. When paid, we make the pricing obvious.</p>

          <h2>Where is my data stored?</h2>
          <p>In the current version of SkillUp Academy, your data is stored locally in your browser.</p>

          <h2>What is your process for finding resources?</h2>
          <p>Our CEO, Danny Forest, explain our proccess in <a href="https://medium.com/skilluped/how-to-figure-out-everything-you-need-to-know-about-a-topic-685a2be544ce" target="_blank" rel="noopener noreferrer">this article</a>.</p>
          <p>Outside of doing online searches, we also seek out experts and get their help for finding resources they deem relevant.</p>

          <h2>What is skill proficiency?</h2>
          <p>Within SkillUp Academy, you can rate your proficiency level for every skill we support. We use a scale of 0-4, identified by stacked horizontal bars, like this: <Proficiency level={3} /></p>
          <p>Here's what each level means:</p>
          <ul>
            <li><Proficiency level={0} /> <strong>0 - No proficiency</strong>: You don't yet know anything about the skill.</li>
            <li><Proficiency level={1} /> <strong>1 - Beginner</strong>: You know some concepts and can perform limited techniques.</li>
            <li><Proficiency level={2} /> <strong>2 - Intermediate</strong>: You know many concepts and can perform some techniques.</li>
            <li><Proficiency level={3} /> <strong>3 - Expert</strong>: You know most concepts and can perform many techniques.</li>
            <li><Proficiency level={4} /> <strong>4 - Master</strong>: You have nothing else to learn.</li>
          </ul>

          <h2 id="archetypes">What are learner archetypes?</h2>
          <p>Learner archetypes are different types of learners. At SkillUp Academy, we divide them into three main ones:</p>
          <ul>
            <li><strong>Generalist</strong>: Someone who likes to learn many skills and rarely aims to master any of them. This is also commonly known as the Jack-of-all-trades.</li>
            <li><strong>Specialist</strong>: Someone who likes to get very good at, or master, a select few skills that relate to each other.</li>
            <li><strong>Polymath</strong>: Someone who likes to master some skills from different areas of expertise. The Polymath sits between the Generalist and the Specialist.</li>
          </ul>

          <h2 id="learningMethods">What are the different learning methods?</h2>
          <p>Not everyone likes to learn the same way. At SkillUp Academy, we divide the learning methods into three main ones:</p>
          <ul>
            <li><strong>Focusing on strengths</strong>: Someone who prefers to get better at things they already have some proficiency in.</li>
            <li><strong>Focusing on weaknesses</strong>: Someone who prefers to get better at things they are not very good at.</li>
            <li><strong>Balance strengths and weaknesses</strong>: Someone who prefers to balance skills as evenly as possible.</li>
          </ul>

          <h2 id="chronotypes">What are the different chronotypes?</h2>
          <p>Your chronotype is a classification of when your genetic propensity is to sleep. It’s determined by the PER3 gene. If you have a long PER3 gene, you’re an early riser. If you have a shorter PER3 gene, you’re a late riser. In SkillUp Academy, we divide them into three categories:</p>
          <ul>
            <li><strong>Third Bird</strong>: Someone who doesn't wake up early or late. They have high enough energy throughout the day. Most people are third birds.</li>
            <li><strong>Night Owl</strong>: Someone who wakes up late. They have higher energy in the evening.</li>
            <li><strong>Lark</strong>: Someone who wakes up early. They have higher energy in the morning.</li>
          </ul>

          <h2>How can I know what to learn next?</h2>
          <p>We have a tool to help you with that sitting in your profile page, <a href="/me#recommendations">Check it out</a>!</p>

          <h2>Do you need help?</h2>
          <p>Yes, we do! You can find out how you can help from our <a href="/contribute">contribute</a> page.</p>
        </div>
      </div>
    );
  }
}

export default FAQ;