import React from 'react';
import { Hub } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import './MyProfile.css';
import Header from '../../components/Header';
import Recommendations from '../../Recommendations';
import MySkillDistribution from './MySkillDistribution';
import MyProfilePreferences from './MyProfilePreferences';
import ReflectionJournal from './ReflectionJournal';
import User from '../../User';

const SUB_PAGES = {
  JOURNAL: 0,
  BADGES: 1,
  COLLECTIONS: 2,
  RECOMMENDATIONS: 3,
  STATS: 4,
  PREFERENCES: 5
}

class MyProfile extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      subPage: SUB_PAGES.JOURNAL
    }

    Hub.listen('auth', this.listener)
  }
  
  componentDidMount() {
    new User().load().then(user => {
      this.setState({user: user});
    });
  }

  listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        this.props.history.push('/me');
        break;
      case 'signOut':
        window.location.reload();
        break;
      case 'signUp':
        this.state.user.save({username: data.payload.username});
        // this.props.history.push('/me');
        break;
      default:
        break;
    }
  }

  updateUser = (user) => {
    this.setState({user: user});
    this.updateBackend(user);
  }

  updateBackend(user) {
    if (typeof user.save !== "function") return;

    const data = {
      username: user.data.username,
      archetype: user.data.archetype,
      learningMethod: user.data.learningMethod,
      chronotype: user.data.chronotype
    };

    
    user.save(data);
  }

  isMobile = () => window.innerWidth < 550

  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper" style={{paddingTop: '18vh'}}>
          {this.renderSignedIn()}
          {/* {this.state.user && !this.state.user.isNew && this.renderSignedIn()}
          {this.state.user && this.state.user.isNew && this.renderSignedOut()} */}
        </div>
      </div>
    )
  }

  renderSignedIn() {
    const mainContentHorizontalPadding = this.isMobile() ? '0.5em' : '3em'

    return (
      <div>
        {/* <AmplifySignOut /> */}
        {/* <br /><br /> */}
        {/* <button onClick={() => this.props.history.push('/me/reflection')}>My Reflection Journal</button> */}
        {/* <MyProfileNavigation /> */}
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start'}}>
            {!this.isMobile() && 
            <div>
              <ToggleButtonGroup style={{flexWrap: 'wrap'}} exclusive orientation='vertical' value={this.state.subPage} onChange={(event, subPage) => this.setState({subPage: subPage})} aria-label="Skill Subpage">
                <ToggleButton style={{width: '160px'}} value={0} aria-label="Journal">Journal</ToggleButton>
                <ToggleButton style={{width: '160px'}} value={1} aria-label="Badges">Badges</ToggleButton>
                <ToggleButton style={{width: '160px'}} value={2} aria-label="Collections">Collections</ToggleButton>
                <ToggleButton style={{width: '160px'}} value={3} aria-label="Recommendations">Recommendations</ToggleButton>
                <ToggleButton style={{width: '160px'}} value={4} aria-label="Statistics">Statistics</ToggleButton>
                <ToggleButton style={{width: '160px'}} value={5} aria-label="Profile">Profile</ToggleButton>
              </ToggleButtonGroup>
              <br /><br />
            </div>
            }
            <div style={{paddingLeft: mainContentHorizontalPadding, paddingRight: mainContentHorizontalPadding, flexGrow: 1, maxWidth: '90vw'}}>
              {(this.state.subPage === SUB_PAGES.JOURNAL || this.isMobile()) && <ReflectionJournal />}
              {(this.state.subPage === SUB_PAGES.COLLECTIONS || this.isMobile()) && 
                <div>
                  <h2 style={{padding: 0, margin: 0}}>Collections</h2>
                  <h3>Saved resources</h3>
                  <p>Coming soon!</p>
                  <h3>Saved jobs</h3>
                  <p>Coming soon!</p>
                </div>
              }
              {(this.state.subPage === SUB_PAGES.RECOMMENDATIONS || this.isMobile()) && <Recommendations user={this.state.user} />}
              {(this.state.subPage === SUB_PAGES.STATS || this.isMobile()) && <MySkillDistribution user={this.state.user} />}
              {(this.state.subPage === SUB_PAGES.BADGES || this.isMobile()) && 
                <div>
                  <h2 style={{padding: 0, margin: 0}}>Badges</h2>
                  <p>Coming soon!</p>
                </div>
              }
              {(this.state.subPage === SUB_PAGES.PREFERENCES || this.isMobile()) && <MyProfilePreferences user={this.state.user} updateUser={this.updateUser.bind(this)} />}
            </div>
          </div>        
      </div>
    );
  }

  renderSignedOut() {
    return (
      <div>
        <button onClick={() => this.props.history.push('/signin')}>Sign in or Create Account</button>
      </div>
    );
  }
}

export default withRouter(MyProfile);