import React from 'react';
import HeaderNavigationMenu from './HeaderNavigationMenu';

const selectedStyle = {
  backgroundColor: 'orange',
  borderRadius: '10px'
}

const normalStyle = {}

class HeaderNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  getStyle = (urlText) => window.location.href.includes(urlText) ? selectedStyle : normalStyle

  render() {

    if (this.state.width < 850) {
      return <HeaderNavigationMenu color={this.props.color} />;
    } else {
      return (
        <div className="header-nav">
          <nav className="header-nav-list">
            <div className="header-nav-item" style={this.getStyle('explore')}>
              <a href="/explore?source=skills" style={{color: this.props.color}}>Learn</a>
            </div>
            <div className="header-nav-item" style={this.getStyle('jobs')}>
              <a href="/jobs" style={{color: this.props.color}}>Work</a>
            </div>
            <div className="header-nav-item" style={this.getStyle('me')}>
              <a href="/me" style={{color: this.props.color}}>Act</a>
            </div>
          </nav>
        </div>
      )
    }
  }
}

export default HeaderNavigation;