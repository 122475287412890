import React from 'react';
import '../../Skill.css';
import User from '../../User';
import Header from '../../components/Header';
import RelatedSkills from '../../RelatedSkills.js';
import GoogleTrends from '../../components/GoogleTrends';
import SkillResources from '../../SkillResources.js';
import SkillDbCollections from '../../SkillDbCollections'
const { skills, families } = SkillDbCollections;

class Tag extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {    
    new User().load().then(user => {
      this.setState({user: user});
    });
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  render() {

   var tagName = this.props.match.params.tag;
    tagName = decodeURIComponent(tagName);
    const tag = families[tagName];

    if(!tag) {
      return (
        <div>
          <Header />
          <div className="content-wrapper">
            <h2>{this.titleCase(tagName.replaceAll('-', ' '))} isn't yet supported.</h2>
          </div>
        </div>
      )
    }

    const filtered = skills.filter(s => s.hasOwnProperty('families') && s.families.includes(tagName)).sort();
    tag.relatedSkills = filtered.map(s => s._id );
    if(!tag.resources) {
      tag.resources = [];
    }

    return (
      <div>
        <Header color='black' />
        <div className="content-wrapper" style={{paddingTop: '16vh'}}>
          
          <div style={{textAlign: 'center'}}>
              <h1 id="top" style={{paddingTop: 0, marginTop: 0}}>{this.titleCase(tagName)}</h1>
          </div>
          <br />
          
            <section>
             {this.renderTrends(tagName)}<br /><br />
             {this.renderJobs(tagName)}<br /><br />
              <div>
                {
                  tag &&
                    <RelatedSkills skill={tag} user={this.state.user} skills={skills} />
                }
              </div>
              
              {
                tag && tag.hasOwnProperty('resources') && tag.resources.length > 0 &&
                  <SkillResources skill={tag} />
              }
              <br style={{clear:'both'}} />
             
            </section>
        </div>
      </div>
    )
  }

  renderTrends(name) {
    return (
      <div id="widget">
        <h2 style={{paddingTop: 0, marginTop: 0}}>Trends</h2>
        <GoogleTrends
          type="TIMESERIES"
          keyword={name}
          url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
        />
      </div>
    )
  }

  renderJobs(name) {
    return (
      <div>
        <h2>Jobs</h2>
        <ul>
          <li>
            <a href={`https://www.indeed.com/q-${name.toLowerCase().replaceAll(' ', '-')}-jobs.html`} target="_blank" rel="noopener noreferrer">Indeed.com</a>
          </li>  
        </ul>
      </div>
    )
  }

}

export default Tag;