import React from 'react';
import PieChart from "../../components/PieChart"
import SkillDbCollections from '../../SkillDbCollections'
import SkillsGrid from '../../SkillsGrid';
const { categories } = SkillDbCollections;

class MySkillDistributionFavorites extends React.Component {
  render() {
    if (!this.props.user.data.favorites) this.props.user.data.favorites = {items: []};

    const favorites = this.props.user.data.favorites.items.map(i => i.skillId);
    const total = favorites.length;

    let data = Object.values(categories).filter(c => c.isActive).map(category => {
      let percentage = total? Math.round(this.props.getNumSkillsCategory(this.props.skills, favorites, category.name)/total*100): 0;

      return {
        percentage: percentage,
        color: category.color,
        label: category.name,
        link: `/explore?source=skills&category=${encodeURIComponent(category.name)}&${"favorites=true"}`
      }
    })

    data.sort(function(a, b) {
      return a.label.localeCompare(b.label)
    });

    return (
      <div style={{maxWidth: '380px'}}>
        <h2 style={{padding: 0, margin: 0}}>Skills I want to learn</h2>
        <p>Number of skills I want to learn: {total}</p>
        <PieChart 
          data={data}
        />
        <br />
        <SkillsGrid skills={this.props.user.data.favorites.items.map((item) => this.props.skills[item.skillId])} />    
      </div>
    );
  }
}

export default MySkillDistributionFavorites;