import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import skills from '../../skills.json';
import convertArrayToObject from '../../utilities';

import MySkillDistributionFavorites from './MySkillDistributionFavorites';
import MySkillDistributionProficiency from './MySkillDistributionProficiency';

class MySkillDistribution extends React.Component {
  getMax(skills, array) {
    const count = {
      'logical': this.getNumSkillsCategory(skills, array, 'Logical/Analytical'),
      'musical': this.getNumSkillsCategory(skills, array, 'Musical-rhythmic'),
      'visual': this.getNumSkillsCategory(skills, array, 'Visual-spatial'),
      'verbal': this.getNumSkillsCategory(skills, array, 'Verbal-Linguistic'),
      'bodily': this.getNumSkillsCategory(skills, array, 'Bodily-kinesthetic'),
      'interpersonal': this.getNumSkillsCategory(skills, array, 'Interpersonal'),
      'intrapersonal': this.getNumSkillsCategory(skills, array, 'Intrapersonal'),
      'naturalistic': this.getNumSkillsCategory(skills, array, 'Naturalistic')
    };

    return Math.max(...Object.values(count));
  }

  getNumSkillsCategory(skills, ids, category) {
    return ids.filter(id => skills[id] && skills[id].categories && skills[id].categories.indexOf(category) !== -1).length;
  }

  renderProgress(category, className, skills, array, max, total, qs) {
    const num = this.getNumSkillsCategory(skills, array, category);
    return (
      <div>
        <span style={{float: 'left', width: '25vw', maxWidth:"200px", minWidth: "175px"}}>{category}: </span>
        <progress className={className} value={num} max={max} />&nbsp;
        {max > 0 &&
          <span>
            <a href={`/index?category=${category}&${qs}`}>
              {Math.round(num/total*100)}%
            </a>
          </span>
        }
        <br />
      </div>
    );
  }

  render() {
    const grouped = convertArrayToObject(skills, '_id');

    if (!this.props.user) {
      return (
        <div>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
        </div>
      );
    }

    return (
      <div style={{display: 'flex', columnGap: '20px', flexWrap: 'wrap'}}>
        <MySkillDistributionFavorites
          user={this.props.user}
          skills={grouped}
          getMax={this.getMax.bind(this)}
          getNumSkillsCategory={this.getNumSkillsCategory.bind(this)}
          renderProgress={this.renderProgress.bind(this)} 
        /><br /><br />
        <MySkillDistributionProficiency
          user={this.props.user}
          skills={grouped}
          getMax={this.getMax.bind(this)}
          getNumSkillsCategory={this.getNumSkillsCategory.bind(this)}
          renderProgress={this.renderProgress.bind(this)}
        />
      </div>
    )
  }

}

export default MySkillDistribution;