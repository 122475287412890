import React from 'react';
import CustomXGridContainer from './components/CustomXGridContainer';
import CustomXGridTheme from './components/CustomXGridContainer/Theme';

const emojiMapping = {
  'App': '📱',
  'Article': '📰',
  'Video': '📹',
  'Channel': '📺',
  'Book': '📙',
  'PDF': '📄',
  'Reddit': '🧵',
  'Influencer': '🗣', 
  'Course': '👩‍🏫',     
  'Community': '🧑‍🤝‍🧑',      
  'Group': '🧑‍🤝‍🧑',
  'eBook': '📘',      
  'Tool': '🧰',
  'Trainer': '👟',      
  'Website': '🌐'
};

class SkillResources extends CustomXGridContainer {
  
  state = {}

  componentDidMount() {
    super.componentDidMount();
  }
  
  renderResource(params) {
    return <a href={params.value.url} target="_blank" rel="noopener noreferrer">
      {params.value.name.length < 65 ? params.value.name : `${params.value.name.substring(0, 65)}...`}
    </a>
  }

  getData() {
    return {
      columns: [
        {field: 'id', hide: true},
        {field: 'type', headerName: 'Type', width: 130},
        {field: 'resource', headerName: 'URL', width: 500, renderCell: this.renderResource},
        {field: 'tag', headerName: 'Tag', width: 200},
      ],
      rows: this.getRows()
    }
  }

  getRows() {
    let emojiMappingKeys = Object.keys(emojiMapping);
    // For large array use object insted of array
    // let order = emojiMappingKeys.reduce((r, k, v) => Object.assign(r, { [k]: v }), {});

    const resources = this.props.skill.hasOwnProperty('resources') ? this.props.skill.resources : []

    return resources.sort(function(a, b) {
       return emojiMappingKeys.indexOf(a.type) - emojiMappingKeys.indexOf(b.type);
    }).map((resource, index) => {
      return {
        id: index,
        type: emojiMapping.hasOwnProperty(resource.type) ? `${emojiMapping[resource.type]} ${resource.type}` : resource.type,
        resource: resource,
        tag: resource.subtype,
      };
    });
  }

  cellClicked = (param) => {}

  render() {
    const data = this.getData();

    return (
      <div>
        <h2 id="resources" style={{paddingTop: 0, marginTop: 0}}>Resources</h2>
        {!this.props.isMobile && this.renderGrid(data, this.cellClicked, [])}
        {this.props.isMobile && <ul>{this.renderMobile()}</ul>}
      </div>
    );
  }

  renderMobile() {
    let emojiMappingKeys = Object.keys(emojiMapping);
    const resources = this.props.skill.hasOwnProperty('resources') ? this.props.skill.resources : []

    return resources.sort(function(a, b) {
       return emojiMappingKeys.indexOf(a.type) - emojiMappingKeys.indexOf(b.type);
    }).map((resource, index) => {
      return (
        <li key={index}>
          <a href={resource.url} target='resource'>
            {emojiMapping.hasOwnProperty(resource.type) ? emojiMapping[resource.type] : resource.type} &nbsp;
            {resource.name}
          </a>
        </li>
      )
    });
  }

}

export default CustomXGridTheme(SkillResources);