import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Proficiency from '../../Proficiency';

class ProficiencyBar extends React.Component {
  
  state = {
    level: 0,
    levels: [
      {
        'name': 'None',
        value: 0
      },
      {
        'name': 'Beginner',
        value: 1
      },
      {
        'name': 'Intermediate',
        value: 2
      },
      {
        'name': 'Expert',
        value: 3
      },
      {
        'name': 'Master',
        value: 4
      }
    ]
  }

  getLevel() {
    if (this.props.level !== undefined) return this.props.level;
    if (!this.props.user.data.mastery) return 0;
    if (!this.props.user.data.mastery.items.length === 0) return 0;

    const selected = this.props.user.data.mastery.items.find(i => i.skillId === this.props.skillId);
    
    return selected ? selected.proficiency : 0;
  }

  handleClick = ( value ) => {
    if (!this.props.user || this.props.readOnly) return;

    let level = parseInt(value);

    this.props.user.updateMastery(this.props.skillId, level).then(response => {
      this.forceUpdate();
    });
  }

  render() {
    if (this.props.level === undefined) {
      if (!this.props.user) return <Skeleton />
      if (!this.props.user.data) return <Skeleton />
      if (!this.props.user.data.mastery) return <Skeleton />
    }

    const selectedLevel = this.getLevel();

    return (
      <div>
        {this.state.levels.map((level,index)=>{
          let checked = (index === selectedLevel? true: false);          
          return (
            <div
              key={"lavel-"+index}
              style={{
                display: 'inline-block',
                width: '60px',
                padding: '12px 0px',
                border: '1px solid orange',
                paddingLeft: '5px',
                marginRight: '5px',
                borderRadius: 10,
                position: 'relative',
                cursor: 'pointer'
              }}
              onClick={this.handleClick.bind(this, level.value)}
            >
              {checked?(
                <input 
                  type="radio"
                  name="proficiency"
                  value={level.value}
                  style={{
                    width: '1.2em',
                    height: '1.2em'
                  }}                  
                  checked
                  onChange={()=>{}}
                />
              ):(
                <input 
                  type="radio"
                  name="proficiency"
                  value={level.value}
                  style={{
                    width: '1.2em',
                    height: '1.2em'
                  }}
                />
              )}
              <Proficiency level={level.value} readOnly />
            </div>
          );
        })}
      </div>
    )
  }
}

export default ProficiencyBar;