import React from 'react';
import ProficiencyFilter from '../../ProficiencyFilter';
import PieChart from "../../components/PieChart"
import SkillDbCollections from '../../SkillDbCollections'
import SkillsGrid from '../../SkillsGrid';
const { categories } = SkillDbCollections;

class MySkillDistributionProficiency extends React.Component {
  constructor(props) {
    super(props);  
    this.state = {
      proficiencies: [1, 2, 3, 4]
    };
  }

  //
  // Utilities

  filtered(mastery) {
    return mastery.filter(m => this.state.proficiencies.includes(m.proficiency)).map(m => m.skillId);
  }


  //
  // Callbacks

  onFiltersUpdated(newFilters) {
    this.setState({proficiencies: newFilters});
  }


  //
  // Rendering

  render() {
    if (!this.props.user.data.mastery) this.props.user.data.mastery = {items:[]};

    const ids = this.filtered(this.props.user.data.mastery.items);
    const total = ids.length;
    const proficiencies = this.state.proficiencies.join(',');

    let data = Object.values(categories).filter(c => c.isActive).map(category => {
      let percentage = total? Math.round(this.props.getNumSkillsCategory(this.props.skills, ids, category.name)/total*100) : 0;

      return {
        percentage: percentage,
        color: category.color,
        label: category.name,
        link: `/explore?source=skills&category=${encodeURIComponent(category.name)}&&${"proficiencies="}${proficiencies}`
      }
    })

    data.sort(function(a, b) {
      return a.label.localeCompare(b.label)
    });

    return (
      <div style={{maxWidth: '380px'}}>
        <h2 style={{padding: 0, margin: 0}}>Skills I know</h2>
        {/* <ProficiencyFilter proficiencies={this.state.proficiencies} onFiltersUpdated={this.onFiltersUpdated.bind(this)} /> */}
        <p>Number of skills I know: {total}</p>
        
        <PieChart 
          data={data}
          proficiencies={proficiencies}
        />
        <br />
        <SkillsGrid skills={ids.map((id) => this.props.skills[id])} />
        {/* TODO: Check > 0 */}
      </div>
    );
  }
}

export default MySkillDistributionProficiency;