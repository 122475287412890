import React from 'react';

import SearchBarBasic from '../../SearchBarBasic.js';

import testimonials from '../../testimonials.json';

class Courses extends React.Component {
  constructor(props) {
    super(props);

    this.siteTitle = window.location.hostname.includes('skillup') ? 'SkillUp Academy' : 'Skills DB';
  }
  render() {
    return (
      <div>
        <div className="content-wrapper">
          <h1 style={{paddingTop: 0, marginTop: 0}}>Featured</h1>
          <h2 style={{paddingTop: 0, marginTop: 0}}>10 Important Skills to Learn in 10 Days to Fast-track Your Learning of Any Skill</h2>
          <p>Are you struggling to learn new skills? This course could forever change that for you!</p>
          <p>I used the think that I couldn't learn skills outside of my realm of expertise. When I started learning 3 new skills a month 3 years ago, I was baffled at everything I could to learn!</p>
          <p>With the skills you'll learn in this course, this could be you!</p>
          <center>
            <div>
              <button><a href="https://skillupedia.podia.com/blitz-course-10-skills-improve-your-learning-in-10-days" target="_blank" rel="noreferrer">View Syllabus</a></button>
            </div>
            <br />

            <h3>Testimonials</h3>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '25px'}}>
              {
                Object.keys(testimonials).map((k, index) => 
                  <div key={"testimonial-"+index} className="testimonial" style={{flex: '1 0 21%'}}>
                    <h4>{testimonials[k].name}</h4>
                    <div>
                      <img src={testimonials[k].photo} alt={testimonials[k].name} />
                    </div>
                    <p>"{testimonials[k].text}"</p>
                    <br />
                  </div>  
                )
              }
            </div>
            <br />
            <div>
              <button><a href="https://skillupedia.podia.com/blitz-course-10-skills-improve-your-learning-in-10-days" target="_blank" rel="noreferrer">View Syllabus</a></button>
            </div>
          </center>
        </div>
        <br />
        <div className="dark-section">
          <div className="content-wrapper">
            <h2>Other great courses about learning</h2>
            <h3>Learning How to Learn: Powerful mental tools to help you master tough subjects [Coursera]</h3>
            <p>This course gives you easy access to the invaluable learning techniques used by experts in art, music, literature, math, science, sports, and many other disciplines. We’ll learn about the how the brain uses two very different learning modes and how it encapsulates (“chunks”) information. We’ll also cover illusions of learning, memory techniques, dealing with procrastination, and best practices shown by research to be most effective in helping you master tough subjects. </p>
            <div>
              <a href="https://www.coursera.org/learn/learning-how-to-learn">Check it out - Free</a>
            </div>
          </div>
          <br />
        </div>
        <div className="content-wrapper">
          <SearchBarBasic openSkillpage={true} />
        </div>
      </div>
    )
  }
}

export default Courses;