import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

class MyProfilePreferences extends React.Component {

  //
  // Methods Handlers

  handleArchetypeChange = (archetype) => {
    const user = this.props.user;
    user.data.archetype = archetype;
    this.saveUser(user);
  }

  handleLearningMethodChange = (learningMethod) => {
    const user = this.props.user;
    user.data.learningMethod = learningMethod;
    this.saveUser(user);
  }

  handleChronotypeChange = (chronotype) => {
    const user = this.props.user;
    user.data.chronotype = chronotype;
    this.saveUser(user);
  }

  saveUser(user) {
    this.props.updateUser(user);
  }

  //
  // Rendering

  render() {
    return (
      <div>
        <h2 id="preferences" style={{padding: 0, margin: 0}}>Learner Profile</h2>
        {this.renderLearnerArchetype()}
        {this.renderLearningMethod()}
        {this.renderLearnerChronotype()}
      </div>
    )
  }

  renderLearnerArchetype() {
    if (!this.props.user) return this.renderSkeleton();
    
    return (
      <div>
        <h4>Archetype</h4>
        <input type="radio" name="archetype" checked={this.props.user.data.archetype === 'specialist'} onChange={this.handleArchetypeChange.bind(this, 'specialist')} />Specialist (<a href='/faq#archetypes'>?</a>)<br />
        <input type="radio" name="archetype" checked={this.props.user.data.archetype === 'generalist'} onChange={this.handleArchetypeChange.bind(this, 'generalist')} />Generalist (<a href='/faq#archetypes'>?</a>)<br />
        <input type="radio" name="archetype" checked={this.props.user.data.archetype === 'polymath'} onChange={this.handleArchetypeChange.bind(this, 'polymath')} />Polymath (<a href='/faq#archetypes'>?</a>)
      </div>
    );
  }
  
  renderLearningMethod() {
    if (!this.props.user) return this.renderSkeleton();
    
    return (
      <div>
        <h4>Learning Method</h4>
        <input type="radio" name="learningMethod" checked={this.props.user.data.learningMethod === 'strengths'} onChange={this.handleLearningMethodChange.bind(this, 'strengths')} />Focus on strengths (<a href='/faq#learningMethods'>?</a>)<br />
        <input type="radio" name="learningMethod" checked={this.props.user.data.learningMethod === 'weaknesses'} onChange={this.handleLearningMethodChange.bind(this, 'weaknesses')} />Focus on weaknesses (<a href='/faq#learningMethods'>?</a>)<br />
        <input type="radio" name="learningMethod" checked={this.props.user.data.learningMethod === 'balanced'} onChange={this.handleLearningMethodChange.bind(this, 'balanced')} />Balance strengths and weaknesses (<a href='/faq#learningMethods'>?</a>)
      </div>
    );
  }

  renderLearnerChronotype() {
    if (!this.props.user) return this.renderSkeleton();
    
    return (
      <div>
        <h4>Chronotype</h4>
        <input type="radio" name="chronotype" checked={this.props.user.data.chronotype === 'third'} onChange={this.handleChronotypeChange.bind(this, 'third')} />Third Bird (<a href='/faq#chronotypes'>?</a>)<br />
        <input type="radio" name="chronotype" checked={this.props.user.data.chronotype === 'owl'} onChange={this.handleChronotypeChange.bind(this, 'owl')} />Night Owl (<a href='/faq#chronotypes'>?</a>)<br />
        <input type="radio" name="chronotype" checked={this.props.user.data.chronotype === 'lark'} onChange={this.handleChronotypeChange.bind(this, 'lark')} />Lark (<a href='/faq#chronotypes'>?</a>)
      </div>
    );
  }

  renderSkeleton() {
    <div>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  }
}

export default MyProfilePreferences;