import React from 'react';
import './SkillIndex.css';
import Header from './components/Header';
import JobsGrid from './JobsGrid';
import SearchBar from './SearchBarBasic';

class JobIndex extends React.Component {
  
  render() {
    return (
      <div>
        <Header />
        <div style={{paddingTop: '14vh'}}>
          <div className='content-wrapper'>
            <SearchBar 
              openSkillpage={true} 
              hideHeader={true} 
              ref={ x => this.searchBarRef = x }
              source={'jobs'}            
            />
          </div>
          <br />
          <JobsGrid />
        </div>
      </div>
    );
  }
}

export default JobIndex;